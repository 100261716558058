import { AnimatePresence } from "framer-motion";
import { useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import DownloadModal from "./components/DownloadModal";
import FrostedLayer from "./components/FrostedLayer";
import Announcement from "./pages/Announcement";
import Bounties from "./pages/Bounties";
import DiscordCallbackPage from "./pages/DiscordCallbackPage";
import Builder from "./pages/Builder";
import DAO from "./pages/DAO";
import Event from "./pages/Event";
import Claim from "./pages/Claim";
import Home from "./pages/Home";
import IBelieve from "./pages/IBelieve";
import IBuild from "./pages/IBuild";
import ICurious from "./pages/ICurious";
import Resources from "./pages/Resources";
import Tribes from "./pages/Tribes";
import SubmitBounty from "./pages/SubmitBounty";
import DiscordAuthError from "./pages/DiscordAuthError";
import { useGlobalStore } from "./store";
import { colors } from "./styles/colors";
import GlobalStyles from "./styles/GlobalStyles";
import Loading from "./pages/Loading";
import Login from "./components/Login";
import AdminDash from "./pages/AdminDash";
import CreateEvent from "./components/admin/CreateEvent";
import CreateAnnouncement from "./components/admin/CreateAnnouncement";
import CreateBounty from "./components/admin/CreateBounty";
import CreateSeason from "./components/admin/CreateSeason";
import CreateQuest from "./components/admin/CreateQuest";
import CreateDataMap from "./components/admin/CreateDataMap";
import ViewSeason from "./components/admin/ViewSeason";
import ViewMultipliers from "./components/admin/ViewMultipliers";
import ViewQuest from "./components/admin/ViewQuest";
import UserList from "./components/admin/UserList";
import BountySubmissionList from "./components/admin/BountySubmissionList";

import { AuthProvider } from "./context";
import { ProtectedRoutes } from "./guards";
import Sound from './components/Sound'
import Register from "./pages/Register";
import DiscordAuth from "./pages/DiscordAuth";

export default function App() {
  const location = useLocation();
  const { initiateDownload } = useGlobalStore();
  // const [ isLoading, setIsLoading ] = useState(true);
  const { isLoading } = useGlobalStore();

  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <ThemeProvider theme={colors}>
      <Sound />
      <AuthProvider>
        <GlobalStyles />
        {isLoading === false ? (
          <>
            {/* <Nav /> */}
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.key}>
                <Route path="/" element={<Home />} />
                <Route path="/i-curious" element={<ICurious />} />
                <Route path="/i-believe" element={<IBelieve />} />
                <Route path="/i-build" element={<IBuild />} />
                <Route path="/builder" element={<Builder />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/announcement" element={<Announcement />} />
                <Route path="/event" element={<Event />} />
                {/*<Route path="/bounties" element={<Bounties />} />
                <Route path="/bounties/submit/:id" element={<SubmitBounty />} />*/}
                <Route path="/houses" element={<Tribes />} />
                <Route path="/claim" element={<Claim />} />
                <Route path="/openmetadao" element={<DAO />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/discordAuthCallBack/*" element={<DiscordCallbackPage />} />
                <Route path="/discordAuth" element={<DiscordAuth />} />
                <Route path="/discordAuthError" element={<DiscordAuthError />} />
                <Route element={<ProtectedRoutes />}>
                  <Route path="/admin" element={<AdminDash />} />
                  <Route path="/admin/users" element={<UserList />} />
                  <Route path="/admin/create-event" element={<CreateEvent />} />
                  <Route path="/admin/edit-event/:id" element={<CreateEvent />} />
                  <Route path="/admin/create-announcement" element={<CreateAnnouncement />} />
                  <Route path="/admin/edit-announcement/:id" element={<CreateAnnouncement />} />
                  <Route path="/admin/create-bounty/:seasonid/:id" element={<CreateBounty />} />
                  <Route path="/admin/create-season" element={<CreateSeason />} />
                  <Route path="/admin/create-quest/:id" element={<CreateQuest />} />
                  <Route path="/admin/edit-bounty/:id" element={<CreateBounty />} />
                  <Route path="/admin/view-season/:id" element={<ViewSeason />} />
                  <Route path="/admin/view-multipliers" element={<ViewMultipliers />} />
                  <Route path="/admin/create-multiplier" element={<CreateDataMap />} />
                  <Route path="/admin/view-quest/:seasonid/:id" element={<ViewQuest />} />
                  <Route path="/admin/bounty/submissions/:id" element={<BountySubmissionList />} />
                </Route>
              </Routes>
            </AnimatePresence>
            {initiateDownload && <DownloadModal />}
            <FrostedLayer />
          </>
        ) : (
          <Loading />
        )}
      </AuthProvider>
    </ThemeProvider>
  );
}