import { motion } from "framer-motion";
import styled from "styled-components";
import Footer from "../Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Nav from "../NavAdminOnly";
import { AcceptFileType, BountyService, FileUploadService, IBounty } from "../../utilities";
import { FileUploadPickerBountyCreation } from "../FileUploadBountyCreation";

export default function CreateBounty() {

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const params = useParams<{ seasonid: string, id: string }>();

  //Adjust once quest field is available
  const [values, setValues] = useState<IBounty>({
    title: '',
    desc: '',
    AccptCriteria: '',
    omc: 0,
    deadline: '',
    image: '',
    category: 'regular',
    seasonID: params.seasonid || '', 
    tweetID: '',
    questID: params.id || '', 
    allowMultipleEntries: false,
    requireApproval: true,
  })

  const [imageFile, setImageFile] = useState<any[]>([])

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      let reqData = { ...values }
      e.preventDefault();
      const files = [];
      //console.log('the image file', imageFile);
      //console.log('the image file 0', imageFile[0].file);
      if (imageFile.length > 0 && imageFile[0].file) {
        files.push({
          key: "image",
          file: imageFile[0].file,
          isPublic: true,
        })
      } else {
        setErrorMessage("Image file required, please input an image file.");
      }

      const promises = files.map(async (item) => {
        const { signedUrl, keyOrUrl } = await FileUploadService.signedUrl({
          ext: `.${item.file.name.split('.').pop()}`,
          contentType: item.file.file.type,
          isPublic: item.isPublic,
        })
        console.log('await upload')
        await FileUploadService.upload(signedUrl, item.file.file, 'image/png');
        //console.log('url', keyOrUrl)
        return {
          [item.key]: keyOrUrl,
        }
      });
      const fileResult = await Promise.all(promises);
      fileResult.forEach(result => {
        reqData = {
          ...reqData,
          ...result,
        }
      })
      console.log('data for bounty', reqData);
      await BountyService.create(reqData)
      navigate('/admin');
    } catch (error) {
      console.log(error);
    }
  }

  const addedImageFile = (files: any[]) => {
    setImageFile([...files])
  }

  return (
    <>
      <Nav />
      <StyledCreateBounty
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        key="builder"
      >
        <A1>
          <p className="highlight">CREATE Bounty</p>
          <form
            className="create-form"
            onSubmit={(e) => {
              submitHandler(e);
            }}>
            <div className="divider">
              <div className="sections">
                <label>
                  <select
                    className="full-100 custom-dropdown "
                    value={values?.category}
                    onChange={(e) => setValues({ ...values, category: e.target.value })}
                    name="category"
                    required
                  >
                    <option value="regular">Regular</option>
                    <option value="text">Text</option>
                    <option value="automatic">Automatic</option>
                    <option value="video">Video</option>
                    <option value="twitter_like">Twitter Like</option>
                    <option value="twitter">Twitter Retweet</option>
                  </select>
                </label>
                
               {values?.category === 'twitter_like' || values?.category === 'twitter' ? (
                <div>
                  <br/>
                  <br/>
                  <label>
                    <input
                      placeholder="Twitter ID"
                      className="full-100"
                      value={values?.tweetID}
                      onChange={(e) => setValues({ ...values, tweetID: e.target.value })}
                      name="twitter ID"
                      type="text"
                      required
                    />
                  </label>
                  
                </div>
              ) : null}
                 <br />
                  <br />
                
                <label>
                  <input
                    placeholder="Title"
                    className="full-100"
                    value={values?.title}
                    onChange={((e) => setValues({ ...values, title: e.target.value }))}
                    name="title"
                    type="text"
                    required />
                </label>
                <br />
                <br />
                <label>
                  <textarea
                    placeholder="Description"
                    className="full-w"
                    value={values?.desc}
                    onChange={((e) => setValues({ ...values, desc: e.target.value }))}
                    name="desc"
                    required />
                </label>
                <br />
                <br />
                <label>
                  <textarea
                    placeholder="Acceptance Criteria"
                    className="full-w"
                    value={values?.AccptCriteria}
                    onChange={((e) => setValues({ ...values, AccptCriteria: e.target.value }))}
                    name="AccptCriteria"
                    required />
                </label>
                <br />
                <br />
                <div >
                  <label className="left-label lbl-font">Allow Multiple</label>
                  <input
                    className="w-15"
                    checked={values?.allowMultipleEntries || false}
                    onChange={(e) =>
                      setValues({ ...values, allowMultipleEntries: e.target.checked })
                    }
                    name="allowMultipleEntries"
                    type="checkbox"
                  />
                  <label className="left-label lbl-font"># EMC</label>
                  <input
                    placeholder="OMC"
                    className="w-15"
                    value={values?.omc}
                    onChange={((e) => setValues({ ...values, omc: e.target.valueAsNumber }))}
                    name="omc"
                    type="number"
                    min="0"
                    required />
                </div>
                <div>
                  <label className="left-label lbl-font">Require Approval</label>
                  <input
                    className="w-15"
                    checked={values?.requireApproval || false}
                    onChange={(e) =>
                      setValues({ ...values, requireApproval: e.target.checked })
                    }
                    name="requireApproval"
                    type="checkbox"
                  />
                  <label className="left-label lbl-font">DEADLINE</label>
                  <input
                    placeholder="Deadline"
                    value={values?.deadline}
                    onChange={((e) => setValues({ ...values, deadline: e.target.value }))}
                    name="deadline"
                    className="flt-right"
                    type="date"
                    required />
                </div>
                <br />
                <br />
              </div>
              <div className="sections">
                <FileUploadPickerBountyCreation
                  placeholder="Upload Image"
                  maxFiles={1}
                  accept={AcceptFileType.image}
                  addedNewFiles={addedImageFile}
                  files={imageFile}
                />
                <br />
                {errorMessage && <div className="error"> {errorMessage} </div>}
                <br />
                <br />
                <div className="submitFormBtn">
                  <button className="btn" type="submit">POST</button>
                </div>
              </div>
            </div>
          </form>
        </A1>
        <Footer />
      </StyledCreateBounty>
    </>
  );
}

const StyledCreateBounty = styled(motion.main)`
  background-image: url("/images/adminPostBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;

`;

const A1 = styled.section`
  padding: 20vh 0;

  .custom-dropdown {
    position: relative;
    font-size: 0.8rem;
    padding: 0.8rem 1.5rem 0.8rem 1rem;
    background: rgba(90, 90, 90, 0.36);
    border: none;
    backdrop-filter: blur(1rem);
    color: #fff;
    outline: none;
    width: 100%; /* Full width */
    margin: 0; /* Reset margin */
    appearance: none; /* Remove default appearance */
    -webkit-appearance: none; /* Remove default appearance in Safari */
  }

  .custom-dropdown::placeholder {
    color: #6f6969;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  /* Add additional width styles if needed */
  .custom-dropdown.w-9 {
    width: 15%;
  }

  .custom-dropdown.w-15 {
    width: 15%;
    margin-right: 10rem;
  }

  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .custom-dropdown.w-15 {
      margin-right: 8rem;
    }
  }


  .create-form {
    padding: 0rem 3rem;
    @media (max-width: 768px) {
      padding: 0 0;
    }
  }
  .error{
    color:red;
  }
  .divider {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
    }
  }
  .sections {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding:1.5rem;
    }
    div>label > input{
    float: left;
    height: 100%;
    }
    .left-label{
         display: inline-block;
         text-align: right;
         padding-right:20px;
    }
    .lbl-font:{
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
  input,textarea {
    position: relative;
    font-size: 0.8rem;
    padding: 0.8rem 1.5rem 0.8rem 1rem;
    background: rgba(90, 90, 90, 0.36);
    border: none;
    backdrop-filter: blur(1rem);
    color: #fff;
    outline: none;
    &::placeholder {
      color: #6f6969;
    }
    &.img-upload {
      background-image: url("/images/uploadBg.png");
      background-repeat: no-repeat;
      background-position: center;
      width: 35%;
      height: 15rem;
      &::placeholder {
        position: absolute;
        top: 35%;
        left: 39%;
        @media (max-width: 950px) {
          left: 35%;
        }
      }
    }

    &.w-9 { // time
      width: 15%;
    }

    &.w-15 {
      width: 15%;
      margin-right: 10rem;
      @media (max-width: 768px) {
        margin-right: 8rem;
      }
    }

    &.w-cta {
      width: 25%;
      margin-right: 2rem;
    }

    &.w-40 {
      width: 40%;
    }
    &.full-100 {
      width: 100%;
      height:85px;
      
    }
    &.flt-right{
      float:right;
    }
    
    &.full-w {
      width: 100%;
      height: 475px;
      &::placeholder {
        position: absolute;
      }
    }
  }
  .new-cat {
    border: none;
    text-decoration: underline;
    text-transform: none;
    font-size: 0.5em;
    padding: 3em 0em;
  }
  .submitFormBtn {
    display: flex;
    justify-content: flex-end;
    padding: 2rem 0;
  }
  .btn {
    padding: .5rem 2.5rem;
    font-size: .6em;
    @media (max-width: 768px) {
      font-size: .4em;
    }
  }
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
  }
  .highlight {
    padding: 0 5rem;
    font-weight:800;
    font-size: 24px;
    @media (max-width: 768px) {
      padding: 0 1.5rem;
    }
  }
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    text-transform: uppercase;
    display: block;
    background-color: transparent;
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.primary};
    font-size: 0.8rem;
    font-size: clamp(0.8rem, 0.925vw, 0.925vw);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.background};
    }
  }
    h3 {
      text-transform: uppercase;
      margin-top: 1.5rem;
    }
    p {
      font-size: 0.7em;
      margin-bottom: 0.5rem;
    }
  }
  .full-link {
    margin: 6rem auto 0;
  }
`;