
let url;

//Development endpoint
//url = 'http://localhost:3005/api';


//Development endpoint
//url = 'http://dev.openmetaassoc.xyz/api';

// For staging use
//url = 'https://staging.openmetaassoc.xyz/api';


// For live site use
url = 'https://openmetaassoc.xyz/api';

// Force

export const APIPath = {
    server: url,
    users: 'users',
    login: 'auth/login',
    announcement: 'announcements',
    events: 'events',
    bounty: 'bounty/',
    userbounty: 'user/bounty',
    preSignedUrl: 'upload/presigned-url',
    season: 'seasons',
    rewardType: 'rewards/type',
    reward: 'rewards',
    quest: 'quest',
    datamap: 'datamap'
}

// / routes
// server.use('/api/emails', cors(), email)
// server.use('/api/events',event)
// server.use('/api/announcements',announcement)
// server.use('/api/upload',upload) // img upload route
// server.use('/api/auth',admin) // login route for access to 